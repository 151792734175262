<template>
  <div>
    401
  </div>
</template>

<script>
export default {
  name: "Page401",
  data() {
    return {

    };
  },
  methods: {

  }
};
</script>

<style scoped lang="scss">

</style>
